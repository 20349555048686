.header--logo {

  max-width: 1420px;
  margin: 0px auto;

  .logo--link {
    display: block;
    margin: 0px auto;

    height: 2rem;
    margin-top: -2rem;
    margin-bottom: 1rem;

    img {
      display: block;
    }

    @media(min-width: 992px) {
      margin-left: 5.2rem;
      height: 5.5rem;
      margin-top: -1rem;
      width: 295px;
    }

    @media(max-width: 991px) {
      margin-left: 0rem;
      margin-top: 0rem;
      height: 5.5rem;
      width: 230px;

    }

    @media(max-width: 554px) {
      height: 3.357rem;
      margin: 0px auto;
      margin-top: 1rem;
      margin-bottom: 2rem;
      width: 150px;
    }

  }
}


