footer {

  max-width: 1420px;
  margin: 0px auto;

  @media(max-width: 768px) {
    .footer--links {
      h3 {
        font-size: 1.1rem;
        font-weight: bold;
      }
      margin-bottom: 1rem;
    }
  }

  background-color: $hellgrau;
  color: $text-color;
  a {
    color: $text-color;
    &:hover {
      color: $wasserblau;
      text-decoration: none;
    }
  }

  .container {
    padding-top: 2rem;
    padding-bottom: 1rem;
    .footer--links {
      h3 {
        font-size: 0.9rem;
        font-weight: bold;
        margin-bottom: 1.5rem;
      }
      ul {
        list-style-type: none;
        padding-left: 0;

        .nav-link {
          padding: 0;
        }
      }
    }
  }
}

.footer--line {
  margin-top: 5px;
  height: 24px;
  background-color: $wasserblau;
}

