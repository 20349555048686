
.contact--data {

  a {
    color: $weiss;
    text-decoration: underline;
    &:hover {
      color: $orange;
    }
  }

  background-color: $wasserblau;
  max-width: 1420px;
  margin: 0px auto;

  .container {
    .left {
      height: 20rem;
      background-color: $wasserblau;
      color: $weiss;
      padding-top: 2.688rem;
    }

    .right {
      background-color: $weiss;
      iframe {

      }
    }
  }

  .maps-div {
    @media(min-width: 992px) {
      position: relative;
      float: right;
      height: 20rem;
      width: 500px;
    }

    @media(max-width: 991px) {
      position: relative;
      float: none;
      height: 20rem;
      width: 100%;
    }

  }

  .social--icons {
    margin-bottom: 4rem;
  }
}