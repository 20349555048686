


.slider--container {
  position: relative !important;


  max-width: 1420px;
  margin: 0px auto;
  margin-top: 2px;
  //background: url('/website/static/images/layout/erfahrung-und-innovation.png') no-repeat;


  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: center;
  width: 100%;
  height: auto;
  height: 450px;

  @media(max-width: 991px) {
    background-position-y: 2rem;
  }

  .sliderimage--selector {
    position: absolute;
    z-index: 50;
    margin-top: 5rem;
    width: 100%;
    min-height: 3rem;
  }
  .slider--text {
    display: none;
    font-family: Corbel;
    position: absolute;
    width: 300px;
    left: 15%;
    margin: 0px auto;
    margin-top: 6rem;
    color: $weiss;
    font-size: 1.3rem;
    line-height: 1.4rem;
    //background-color: rgba(19, 125, 127, 0.7);
    //background-color: rgba(255, 255, 255, 0.8);
    padding: 20px;

    &:after, :before {
      top: 104%;
      //left: 0.7rem;
      left: 20%;

      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &:after {
      border-color: rgba(255, 255, 255, 0);
      border-top-color: rgba(255, 255, 255, 0.8);
      border-width: 15px;
      margin-left: -15px;
    }
    &:before {
      border-color: rgba(255, 225, 255, 0);
      border-top-color: rgba(255, 255, 255, 0.8);
      border-width: 25px;
      margin-left: -25px;
    }

  }


}