.alert {
  &.alert-gammel {
    background-color: $wasserblau;
    color: $weiss;
  }
}

.sidebar.referenzen {
  @media(min-width: 768px) {
    .broschueren--wrapper {
      position: sticky;
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -ms-sticky;
      position: -o-sticky;
      position: sticky;
      top: 10px;
    }
  }
}

.lexica-sidebar {
  @media(min-width: 768px) {
    .lexica--navigation-wrapper {
      top: 1rem;
      position: sticky;
      position: -webkit-sticky;
      position: -moz-sticky;
      position: -ms-sticky;
      position: -o-sticky;
      position: sticky;
    }
  }
}

.color-primary {
  color: $wasserblau;
}

#reference-map-canvas {
  height: 450px;
}

#contact-map-controls {
  position: relative;
  top: 15px;
  float: right;

  margin-right: 10px;
  z-index: 50;
  background-color: $weiss;
  padding: 5px;
  margin-bottom: -50px;
  border: 1px solid $wasserblau;

}

#contact-map-canvas {
  height: 450px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.responsive-video {
  position: relative;
  padding-bottom: 56.24%; /* Default for 1600x900 videos 16:9 ratio*/
  padding-top: 0px;
  height: 0;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

}

.bg-gray {
  background-color: $gray-light;
}

.sidebar--icons--left {
  list-style-type: none;
  padding-left: 0px;
  text-align: center;

  li {
    margin-bottom: 20px;

    .sidebar-icon {
      .title, .sideheadline {
        margin-top: 0.5rem;
      }
    }
  }
}

.wrap-normal {
  white-space: normal;
}

.pagination-wrapper {
  text-align: center;
}

.card-block.card-inverse {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 auto;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1.25rem;
  color: $text-color;
}

.row.team--member {
}


.blog--entry {
  h1 {
    font-size: 1.5rem;
  }
}

.blog--list {
  h2 {
    font-size: 1.5rem;
  }
}