body {
  margin: 0;
  padding: 0;
}
.gd-sldm * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.gd-sldm .sldm {
  font-family: 'Open Sans', sans-serif;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 150;
}

.gd-sldm .sldm a {
  text-decoration: none;
}

.gd-sldm .sldm a:hover {
  text-decoration: none;
  background-color: #ffffff;
}

.gd-sldm .sldm .sldm-overlay {
  display: none;
}
.gd-sldm .sldm-bg-image {
  width: 100%;
  margin: 43px 0 0;
  padding: 0;
  -webkit-filter: blur(0) grayscale(0);
  filter: blur(0) grayscale(0);

  -webkit-transition: 0.75s all;
  -moz-transition: 0.75s all;
  -o-transition: 0.75s all;
  -ms-transition: 0.75s all;
  transition: 0.75s all;
}
.gd-sldm .sldm-bg-image.active {
  -webkit-filter: blur(5px) grayscale(0.5);
  filter: blur(5px) grayscale(0.5);
}
.gd-sldm .sldm .sldm-bar {
  position: relative;
  z-index: 10;
  min-height: 43px;
  padding: 0 0 0 48px;
  background: #fff;
  -webkit-box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
}

.gd-sldm .sldm .sldm-bar .sldm-brand {
  color: #949494;
  height: 43px;
  line-height: 43px;
  display: block;
  float: left;
  font-weight: 500;
  font-size: 20px;
  text-decoration: none;
  display: inline-block;
  padding-left: 10px;
  -webkit-text-shadow: none;
  -moz-text-shadow: none;
  -o-text-shadow: none;
  -ms-text-shadow: none;
  text-shadow: none;
  background-color: #ffffff;

  -webkit-backface-visibility: hidden;
}

.gd-sldm .sldm .sldm-bar .sldm-brand:hover {
  background: rgba(255, 255, 255, 0.84);
}

.gd-sldm .sldm .sldm-bar .sldm-icons {
  float: right;
}

.gd-sldm .sldm .sldm-bar .sldm-icons a {
  width: 43px;
  height: 43px;
  display: block;
  float: left;
  color: #969696;
  font-size: 24px;
  line-height: 43px;
  text-align: center;
  -webkit-text-shadow: none;
  -moz-text-shadow: none;
  -o-text-shadow: none;
  -ms-text-shadow: none;
  text-shadow: none;

  -webkit-backface-visibility: hidden;

  -webkit-transform: scale(0.9,0.9);
  -moz-transform: scale(0.9,0.9);
  -o-transform: scale(0.9,0.9);
  transform: scale(0.9,0.9);

  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
}

.gd-sldm .sldm .sldm-bar .sldm-icons a:hover {
  background: rgba(0, 0, 0, 0.1);

  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  -o-transform: scale(1,1);
  transform: scale(1,1);
}

.gd-sldm .sldm nav {
  z-index: 30;
  position: fixed;
  top: 0;
  width: 310px;
  height: 100%;
  background: #fff;

  -webkit-transform: translateX(-310px);
  -moz-transform: translateX(-310px);
  -o-transform: translateX(-310px);
  transform: translateX(-310px);

  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
}

.gd-sldm .sldm nav .sldm-nav-container {
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #eeeeee;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-header {
  height: 110px;
  position: relative;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-header a {
  display: block;
  position: absolute;
  left: 40%;
  top: 90%;
  margin-top: -45px;
  width: 95px;
  height: 95px;
  overflow: hidden;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  z-index: 999;

  -webkit-backface-visibility: hidden;

  -webkit-transform: scale(0.9,0.9);
  -moz-transform: scale(0.9,0.9);
  -o-transform: scale(0.9,0.9);
  transform: scale(0.9,0.9);

  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
}
.gd-sldm .sldm nav .sldm-nav-container .sldm-header a:hover {
  -webkit-transform: scale(1,1);
  -moz-transform: scale(1,1);
  -o-transform: scale(1,1);
  transform: scale(1,1);
}
.gd-sldm .sldm nav .sldm-nav-container .sldm-header a img {
  max-width: 100%;
  display: block;
  border: 6px solid #eeeeee;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -o-border-radius: 100%;
  -ms-border-radius: 100%;
  border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-background-clip: padding;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;

}
.gd-sldm .sldm nav .sldm-nav-container .sldm-header .sldm-header-image-wrapper {
  height: 110px;
  overflow: hidden;
  position: relative;
}
.gd-sldm .sldm nav .sldm-nav-container .sldm-header .sldm-header-background-image {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
  right: 0;
  width: 100%;
  height: auto;

}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav {
  padding: 0 0 75px;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-default li {
  line-height: 1.3;
  font-size: 18px;
  font-weight: 300;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-default li a {
  color: #696969;
  display: block;
  padding: 12px 30px 12px 20px;
  text-decoration: none;
  position: relative;

  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
}
.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-default li a:hover,
.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-default li a:focus {
  background-color: rgba(0, 0, 0, 0.15);
}
.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-default li + li {
  border-top: 1px solid #e9e9e9;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-default li.sldm-submenu > a:after {
  display: block;
  content: "\f107";
  font-family: "FontAwesome";
  height: 20px;
  width: 20px;
  line-height: 20px;
  margin-top: -10px;
  position: absolute;
  top: 50%;
  right: 10px;
  text-align: center;

  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-default li.sldm-submenu > a:hover:after,
.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-default li.sldm-submenu > a:active:after {
  right: 10px;
  color: #394c53;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-default li.sldm-submenu > a.sldm-open:after {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-default li.sldm-submenu ul {
  display: none;
  padding-left: 20px;
  border-top: 1px solid #f2f2f2;
  background: #e5e5e5;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-default li.sldm-submenu ul li {
  background: #fff;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-default li.sldm-submenu ul li a {
  font-size: 15px;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-default + ul {
  margin-top: 43px;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-default + ul:before {
  content: "";
  display: block;
  width: 60px;
  height: 3px;
  background: #cfcfcf;
  position: absolute;
  top: -25px;
  left: 50%;
  margin-left: -30px;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-social {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-social:after,
.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-social:before {
  clear: both;
  content: " ";
  display: table;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-social li {
  float: left;
  width: 20%;
  font-size: 24px;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-social li a {
  padding: 12px 0;
  text-align: center;
  display: block;
  background: #dcdcdc;
  color: #585858;

  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
  margin-left: 1px;
  margin-right: 1px;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-social li a:hover {
  background: #fff;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-social li a:hover.sldm-facebook {
  color: $orange;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-social li a:hover.sldm-google-plus {
  color: $orange;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-social li a:hover.sldm-twitter {
  color: $orange;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-social li a:hover.sldm-pinterest {
  color: $orange;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-social li a:hover.sldm-youtube {
  color: $orange;
}

.gd-sldm .sldm nav .sldm-nav-container .sldm-nav ul.sldm-social li a:hover.sldm-xing {
  color: $orange;
}

.gd-sldm .sldm nav .sldm-toggle {
  position: absolute;
  top: 0;
  right: -48px;
  width: 48px;
  height: 43px;
  overflow: hidden;
  display: block;
  cursor: pointer;
  color: #969696;
  background: #fff;
  -webkit-text-shadow: none;
  -moz-text-shadow: none;
  -o-text-shadow: none;
  -ms-text-shadow: none;
  text-shadow: none;

  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);

  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
}

.gd-sldm .sldm nav .sldm-toggle i {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  line-height: 43px;
  font-size: 24px;
  text-align: center;
  left: 0;

  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);

  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
}

.gd-sldm .sldm nav .sldm-toggle i.sldm-close {
  -webkit-transform: translateY(-43px);
  -moz-transform: translateY(-43px);
  -o-transform: translateY(-43px);
  transform: translateY(-43px);
}

.gd-sldm .sldm nav .sldm-toggle i:hover {
  background: rgba(0, 0, 0, 0.1);
}
.gd-sldm .sldm.sldm-active nav .sldm-toggle i {
  -webkit-transform: translateY(43px);
  -moz-transform: translateY(43px);
  -o-transform: translateY(43px);
  transform: translateY(43px);
}

.gd-sldm .sldm.sldm-active nav .sldm-toggle i.sldm-close {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.gd-sldm .sldm.sldm-active .sldm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: block;
  z-index: 20;
}

.gd-sldm .sldm.sldm-active .sldm-overlay.sldm-white {
  background: rgba(255, 255, 255, 0.6);
}

.gd-sldm .sldm.sldm-active nav {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);

  -webkit-box-shadow: -1px 0 5px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: -1px 0 5px rgba(0, 0, 0, 0.15);
  -o-box-shadow: -1px 0 5px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: -1px 0 5px rgba(0, 0, 0, 0.15);
  box-shadow: -1px 0 5px rgba(0, 0, 0, 0.15);
}

@media screen and (max-width:360px) {
  .gd-sldm .sldm.sldm-active nav .sldm-toggle {
    right: 0;
    z-index: 160;
  }
}

@media screen and (max-width:330px) {
  .gd-sldm .sldm.sldm-active nav {
    width: 100% !important;
  }
}

.sldm-widget-container {
  position: fixed;
  top: 43px;
  left: 0;
  width: 100%;
  z-index: 5;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.sldm-widget-container * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.sldm-widget-container .sldm-widget {
  width: 100%;
  background: #696f71;
  height: 60px;
  padding: 10px;
  -webkit-box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 -1px 5px rgba(0, 0, 0, 0.1);
  border-top: 3px solid rgba(255, 255, 255, 0.13);
}

.sldm-widget-container .sldm-widget.sldm-search-widget form {
  position: relative;
  margin: 0;
  padding: 0;
}

.sldm-widget-container .sldm-widget.sldm-search-widget form .sldm-input {
  font-family: 'Open Sans', sans-serif;
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 18px;
  padding: 9px 145px 9px 10px;
  font-weight: 100;
  background: #fff;
  color: #777;
  border: none;
  height: 40px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.sldm-widget-container .sldm-widget.sldm-search-widget form .sldm-input:focus {
  border: none;
  color: #444;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
  -ms-box-shadow: none;
  box-shadow: none;
}

.sldm-widget-container .sldm-widget.sldm-search-widget form .sldm-button {
  font-family: 'Open Sans', sans-serif;
  display: block;
  width: 130px;
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  line-height: 37px;
  border: none;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 100;
  background: #4F758B;
  -webkit-text-shadow: 0 0 0 #32b8e5;
  -moz-text-shadow: 0 0 0 #32b8e5;
  -o-text-shadow: 0 0 0 #32b8e5;
  -ms-text-shadow: 0 0 0 #32b8e5;
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  cursor: pointer;

  -webkit-transition: 0.35s all;
  -moz-transition: 0.35s all;
  -o-transition: 0.35s all;
  -ms-transition: 0.35s all;
  transition: 0.35s all;
}
.sldm-widget-container .sldm-widget.sldm-search-widget form .sldm-button:hover,
.sldm-widget-container .sldm-widget.sldm-search-widget form .sldm-button:focus {
  background-color: #b58500;
}
.sldm-widget-container .sldm-widget.sldm-search-widget form .sldm-button i {
  margin-left: 10px;
}
.sldm-widget-container .sldm-widget.sldm-phone-widget .sldm-phone-widget-content {
  color: #ffffff;
  text-align: center;
  line-height: 43px;
}
.sldm-clear {
  clear: both;
  overflow: hidden;
}

.sldm-hidden {
  display: none;
}