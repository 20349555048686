@charset "UTF-8";

@font-face {
  font-family: "gammel-icons";
  src:url("/website/static/fonts/gammel-icons.eot");
  src:url("/website/static/fonts/gammel-icons.eot?#iefix") format("embedded-opentype"),
  url("/website/static/fonts/gammel-icons.woff") format("woff"),
  url("/website/static/fonts/gammel-icons.ttf") format("truetype"),
  url("/website/static/fonts/gammel-icons.svg#gammel-icons") format("svg");
  font-weight: normal;
  font-style: normal;

}
[data-icon]:before {
  font-family: "gammel-icons" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "gammel-icons" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-blog:before {
  content: "\61";
}
.icon-fernwaerme:before {
  content: "\62";
}
.icon-lexikon:before {
  content: "\63";
}
.icon-potentialanalyse:before {
  content: "\64";
}
.icon-rechner:before {
  content: "\65";
}
.icon-rechner-biomasse:before {
  content: "\66";
}
