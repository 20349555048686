/*!
 * FormValidation (http://formvalidation.io)
 * The best jQuery plugin to validate form fields. Support Bootstrap, Foundation, Pure, SemanticUI, UIKit and custom frameworks
 *
 * @version     v0.8.1, built on 2016-07-29 1:10:54 AM
 * @author      https://twitter.com/formvalidation
 * @copyright   (c) 2013 - 2016 Nguyen Huu Phuoc
 * @license     http://formvalidation.io/license/
 */
.fv-has-feedback {
  position: relative;
}

.fv-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  display: block;
  text-align: center;
}

.fv-help-block {
  display: block;
}

/* Hide the clear icon for field using placeholder attribute on IE10/11 */
.fv-form [placeholder]::-ms-clear {
  display: none;
}

/* -------------------
 * Support Bootstrap 3
 * ------------------- */

.fv-form-bootstrap .help-block {
  margin-bottom: 0;
}

.fv-form-bootstrap .tooltip-inner {
  text-align: left;
}

.fv-form-bootstrap .fv-bootstrap-icon-input-group {
  z-index: 100;
}

/* Inline form */
.form-inline.fv-form-bootstrap .form-group {
  vertical-align: top;
}

/* -------------------------
 * Support Bootstrap 4 alpha
 * ------------------------- */

.fv-form-bootstrap4 .fv-control-feedback {
  width: 38px;
  height: 38px;
  line-height: 38px;
}

/* For horizontal form */
.fv-form-bootstrap4 .form-group.row .fv-control-feedback {
  right: 15px;
}
.fv-form-bootstrap4 .form-group.row .form-check~.fv-control-feedback {
  top: -7px; /* labelHeight/2 - iconHeight/2 */
}
/* Inline form */
.fv-form-bootstrap4.form-inline .form-group {
  vertical-align: top;
}
/* Stacked form */
.fv-form-bootstrap4:not(.form-inline) label~.fv-control-feedback {
  top: 32px;
}
/* Without label */
.fv-form-bootstrap4:not(.form-inline) label.sr-only~.fv-control-feedback {
  top: -7px; /* labelHeight/2 - iconHeight/2 */
}
.fv-form-bootstrap4:not(.form-inline) .form-check~.fv-control-feedback {
  top: 25px; /* labelHeight + checkboxHeight/2 - iconHeight/2 */
}

.fv-form-bootstrap4 .has-success label,
.fv-form-bootstrap4 .has-success .fv-control-feedback {
  color: #5cb85c;
}

.fv-form-bootstrap4 .has-warning label,
.fv-form-bootstrap4 .has-warning .fv-control-feedback {
  color: #f0ad4e;
}

/* --------------------
 * Support Foundation 5
 * -------------------- */

/* Icon position */
.fv-form-foundation5 .fv-control-feedback {
  right: 15px;    /* The padding-right of .columns */
  width: 37px;    /* Same as height of input */
  height: 37px;
  line-height: 37px;
}

.fv-form-foundation5 .row .row:not(.collapse) .fv-control-feedback {
  right: 8px;     /* Multiple fields in the same row, same as padding-right of .row .row .column(s) */
}

.fv-form-foundation5 .row .collapse .fv-control-feedback {
  right: 0;
}

.fv-form-foundation5.fv-form-horizontal [type="checkbox"]~.fv-control-feedback,
.fv-form-foundation5.fv-form-horizontal [type="radio"]~.fv-control-feedback {
  top: -8px;      /* labelHeight/2 - iconHeight/2 */
}

/* Stacked form */
.fv-form-foundation5 label .fv-control-feedback {
  top: 21px;      /* Same as height of label */
}

.fv-form-foundation5 [type="checkbox"]~.fv-control-feedback,
.fv-form-foundation5 [type="radio"]~.fv-control-feedback {
  top: 15px;
}

/* Color */
.fv-form-foundation5 .error .fv-control-feedback {
  color: #f04124; /* Same as $alert-color, .error */
}

.fv-form-foundation5 .fv-has-success label,
.fv-form-foundation5 .fv-has-success .fv-control-feedback {
  color: #43AC6A; /* Same as $success-color */
}

/* --------------------
 * Support Foundation 6
 * -------------------- */

/* Icon position */
.fv-form-foundation .fv-control-feedback {
  right: 15px;    /* The padding-right of .columns */
  width: 39px;    /* Same as height of input */
  height: 39px;
  line-height: 39px;
}

.fv-form-foundation [type="checkbox"]~.fv-control-feedback,
.fv-form-foundation [type="radio"]~.fv-control-feedback {
  top: -7px;      /* labelHeight/2 - iconHeight/2 */
}

.fv-form-foundation .form-error {
  display: block;
}

/* Stacked form */
.fv-form-foundation label .fv-control-feedback,
.fv-form-foundation fieldset [type="checkbox"]~.fv-control-feedback,
.fv-form-foundation fieldset [type="radio"]~.fv-control-feedback {
  top: 25px;      /* Same as height of label */
}

/* Color */
.fv-form-foundation .fv-has-error label,
.fv-form-foundation .fv-has-error fieldset legend,
.fv-form-foundation .fv-has-error .fv-control-feedback {
  color: #ec5840; /* Same as .alert */
}

.fv-form-foundation .fv-has-success label,
.fv-form-foundation .fv-has-success fieldset legend,
.fv-form-foundation .fv-has-success .fv-control-feedback {
  color: #3adb76; /* Same as .success */
}

/* Tooltip */
.fv-foundation-tooltip:before {
  border-color: transparent transparent #0a0a0a;
}

/* ------------
 * Support Pure
 * ------------ */

.fv-form-pure .fv-control-feedback {
  width: 36px;    /* Height of Pure input */
  height: 36px;
  line-height: 36px;
}

/* Horizontal form */
.fv-form-pure.pure-form-aligned [type="checkbox"]~.fv-control-feedback,
.fv-form-pure.pure-form-aligned [type="radio"]~.fv-control-feedback {
  top: -9px;      /* labelHeight/2 - iconHeight/2 */
}

.fv-form-pure.pure-form-aligned .fv-help-block {
  margin-top: 5px;
  margin-left: 180px;
}

/* Stacked form */
.fv-form-pure.pure-form-stacked .pure-control-group {
  margin-bottom: 8px;
}

.fv-form-pure.pure-form-stacked .fv-control-feedback {
  top: 22px;      /* Same as height of label */
}

.fv-form-pure.pure-form-stacked .pure-radio~.fv-control-feedback {
  top: 20px;
}

.fv-form-pure.pure-form-stacked .fv-sr-only~.fv-control-feedback {
  top: -9px;
}

.fv-form-pure .fv-has-error label,
.fv-form-pure .fv-has-error .fv-help-block,
.fv-form-pure .fv-has-error .fv-control-feedback {
  color: #CA3C3C; /* Same as .button-error */
}

.fv-form-pure .fv-has-success label,
.fv-form-pure .fv-has-success .fv-help-block,
.fv-form-pure .fv-has-success .fv-control-feedback {
  color: #1CB841; /* Same as .button-success */
}

/* -------------------
 * Support Semantic UI
 * ------------------- */

.fv-form-semantic.ui.form .fields.error label {
  color: #9f3a38; /* Same as .ui.form .field.error .input */
}

.fv-form-semantic .fv-control-feedback {
  right: 7px;
}

.fv-form-semantic .error .icon {
  color: #9f3a38;
}

/* --------------
 * Support UI Kit
 * -------------- */

.fv-form-uikit .fv-control-feedback {
  top: 25px;      /* Height of UIKit label */
  width: 30px;    /* Height of UIKit input */
  height: 30px;
  line-height: 30px;
}

.fv-form-uikit .uk-text-danger {
  display: block;
}

/* UIKit horizontal form */
.fv-form-uikit.uk-form-horizontal .fv-control-feedback {
  top: 0;
}

.fv-form-uikit.uk-form-horizontal [type="checkbox"]~.fv-control-feedback,
.fv-form-uikit.uk-form-horizontal [type="radio"]~.fv-control-feedback {
  top: -7px;
}

/* Without labels */
.fv-form-uikit label.fv-sr-only~.fv-control-feedback {
  top: -7px !important;
}

/* Stacked form */
.fv-form-uikit.uk-form-stacked [type="checkbox"]~.fv-control-feedback,
.fv-form-uikit.uk-form-stacked [type="radio"]~.fv-control-feedback {
  top: 20px;
}

.fv-form-uikit .fv-has-error label,
.fv-form-uikit .fv-has-error .uk-form-label,
.fv-form-uikit .fv-has-error .fv-control-feedback {
  color: #D85030;     /* Same as .uk-form-danger */
}

.fv-form-uikit .fv-has-success label,
.fv-form-uikit .fv-has-success .uk-form-label,
.fv-form-uikit .fv-has-success .fv-control-feedback {
  color: #659F13;     /* Same as .uk-form-success */
}
