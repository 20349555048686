body {
  padding-bottom: 0px;
}

nav.main {
  margin-bottom: 20px;

  .navbar-brand {
    @include text-hide();
    @include sprite($sprite-logo-gray);
    margin: 9px 0;
  }
}



