.mb-5 {
  margin-bottom: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mtb-5 {
  margin: 5px 0;
}

.mb-10 {
  margin-bottom: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mtb-10 {
  margin: 10px 0;
}

.mb-20 {
  margin-bottom: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mtb-20 {
  margin: 20px 0;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mtb-40 {
  margin: 40px 0;
}