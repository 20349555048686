/*
SCSS variables are information about icon's compiled state, stored under its original file name

.icon-home {
  width: $icon-home-width;
}

The large array-like variables contain all information about a single icon
$icon-home: x y offset_x offset_y width height total_width total_height image_path;

At the bottom of this section, we provide information about the spritesheet itself
$spritesheet: width height image $spritesheet-sprites;
*/
$sprite-logo-gray-name: 'sprite-logo-gray';
$sprite-logo-gray-x: 0px;
$sprite-logo-gray-y: 0px;
$sprite-logo-gray-offset-x: 0px;
$sprite-logo-gray-offset-y: 0px;
$sprite-logo-gray-width: 145px;
$sprite-logo-gray-height: 30px;
$sprite-logo-gray-total-width: 145px;
$sprite-logo-gray-total-height: 30px;
$sprite-logo-gray-image: '/website/static/build/sprites.png';
$sprite-logo-gray: (0px, 0px, 0px, 0px, 145px, 30px, 145px, 30px, '/website/static/build/sprites.png', 'sprite-logo-gray', );
$spritesheet-width: 145px;
$spritesheet-height: 30px;
$spritesheet-image: '/website/static/build/sprites.png';
$spritesheet-sprites: ($sprite-logo-gray, );
$spritesheet: (145px, 30px, '/website/static/build/sprites.png', $spritesheet-sprites, );

/*
The provided mixins are intended to be used with the array-like variables

.icon-home {
  @include sprite-width($icon-home);
}

.icon-email {
  @include sprite($icon-email);
}
*/
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

/*
The `sprites` mixin generates identical output to the CSS template
  but can be overridden inside of SCSS

@include sprites($spritesheet-sprites);
*/
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
