
.site--navigation {

  @media(min-width: 992px) {
    min-height: 9.375rem;
  }
  background-color: $wasserblau;

  .navbar {
    background-color: transparent;
    position: relative;
    @media(min-width: 992px) {
      top: 75px;
    }

    .navbar-toggler {
      color: $weiss;
      border-color: rgba(255, 255, 255, 0.8) !important;
    }

    .nav-item {

      .nav-link {
        border: 1px solid transparent;
        color: #fff;
        text-transform: uppercase;
        font-weight: 100;
        font-size: 1rem;

        &.active {
          border: 1px solid $weiss;

          padding-left: 10px;
          padding-right: 10px;
        }

      }

    }

    &.dropdown {
      .nav-link.active {
        border: 1px solid $weiss;
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }

  .dropdown-menu {
    border-radius: 0px;
    @media(min-width: 768px) {
      margin-top: 0.45rem;
    }
    @media(min-width: 992px) {
      margin-top: 0.95rem;
    }

    margin-top: 0.95rem;
    margin-left: -1rem;
    border-bottom: 10px solid $wasserblau;
    border-left: 1px solid $wasserblau;
    border-right: 1px solid $wasserblau;

    @media(min-width: 768px) {
      &:after {
        content: '';
        position: absolute;
        border-style: solid;
        border-width: 0 9px 9px;
        border-color: #FFFFFF transparent;
        display: block;
        width: 0;
        z-index: 1;
        top: -9px;
        left: 26px;
      }
    }
  }
}

.navigation--container {
  max-width: 1420px;
  margin: 0px auto;


  .responsive--navigation {
    @media(min-width: 555px) {
      display: none;
    }
    background-color: $wasserblau;
    height: 30px;

    .sldm-toggle {
      right: 3rem;
      margin-top: -15px;
      padding-left: 12px;
      padding-top: 4px;
      z-index: 100;
      font-size: 2rem;
      background-color: $wasserblau;
      color: $weiss;
      position: absolute;
      height: 50px;
      width: 50px;
    }
  }



  .site--navigation2 {

    @media(max-width: 554px) {
      display: none;
    }

    z-index: 9999;
    position: relative;

    @media(max-width: 991px) {
      margin-left: 0rem;
    }

    @media(min-width: 992px) {
      margin-left: 5.6rem;
    }

    .navigation {
      background-color: $weiss;
      width: 100%;
      margin-bottom: 0;
      list-style: none;
      padding-left: 0px;

      li {
        display: inline-block;
        a {
          display: block;
          font-size: 1rem;
          padding-right: 1rem;
          padding-left: 1rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          text-decoration: none;
        }

        &.active {
          a {
            position: relative;
            color: $text-color;
          }
        }

        &:not(:first-child) {
          &.active {
            a {
              position: relative;

              &:after, :before {
                top: 80%;
                //left: 0.7rem;
                left: 50%;

                border: solid transparent;
                content: " ";
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
              }

              &:after {
                border-color: rgba(255, 255, 255, 0);
                border-top-color: #ffffff;
                border-width: 15px;
                margin-left: -15px;
              }
              &:before {
                border-color: rgba(255, 225, 255, 0);
                border-top-color: #ffffff;
                border-width: 21px;
                margin-left: -21px;
              }
            }

          }
        }
      }
    }

    .navigation--sub {

      padding-left: 0rem;

      @media(max-width: 991px) {
        margin-bottom: -5rem;
      }
      @media(min-width: 992px) {
        margin-bottom: -4.9rem;
      }

      background-color: $wasserblau;
      background: -webkit-linear-gradient(left, rgba(30, 87, 153, .5) 0%, rgba(25, 106, 140, .5) 0%, rgba(19, 125, 127, 0.14) 0%, rgba(19, 125, 127, 1) 1%); /* Chrome10-25,Safari5.1-6 */

      width: 100%;
      //padding-bottom: 1px;
      border-bottom: 1px solid $weiss;
      list-style: none;

      li {
        display: inline-block;

        .active {
          border-bottom: 3px solid $orange;
          margin-bottom: -2px;
        }
        a {
          color: $weiss;
          text-decoration: none;
          font-weight: normal;
          display: block;
          font-size: .9rem;
          padding-right: 1rem;
          padding-left: 1rem;
          padding-top: 0.5rem;
          padding-bottom: 0.5rem;
          text-align: center;

          &:hover {
            color: $orange;
          }
        }

      }
    }
  }

}