.actionbuttons--container {

  @media(max-width: 767px) {
    //display: none;
  }
  @media(max-width: 991px) {
    padding-top: 1rem;
    padding-bottom: .5rem;
    background-color: $hellgrau2;

  }
  @media(min-width: 992px) {
    margin-top: .4rem;
    border-top: 1px solid $wasserblau;

    //border-bottom: 2px solid $wasserblau;
  }

  max-width: 1420px;
  margin: 0px auto;

  /*@media(max-width: 991px) {
    &.top {
      display: none;
    }
    &.bottom {
      display: block;
    }
  }

  @media(min-width: 992px) {
    &.top {
      display: block;
    }
    &.bottom {
      display: none;
    }
  }
  */

  .container {
    padding-top: 1rem;
    padding-bottom: .5rem;

    .ge-icon {
      text-align: center;
      //padding-bottom: 1rem;

      a {
        text-decoration: none;

        i {
          @media(max-width: 991px) {
            font-size: 2rem;
          }
          @media(min-width: 992px) {
            font-size: 3rem;
          }
          text-align: center;
        }

        .title {

          margin-top: -.5rem;
          font-weight: bold;
          text-align: center;
        }

        .description {
          text-align: center;
          font-size: 0.7rem;
        }

        &:hover {
          color: $orange;
        }
      }

    }

  }
}
