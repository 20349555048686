/* -----------------------------------------------
 * Timeline
 * --------------------------------------------- */

$timeline-line: #dfdfdf;
$timeline-border: $wasserblau;

.timeline--container {
  padding: 60px 0;

  .timeline-main-heading {
    text-transform: uppercase;
    display: blocK;
    margin-bottom: 0;
  }

  .timeline-body {
    padding-right: 1rem;
  }

  .timeline-heading {
    padding-right: 1rem;
  }


  .spacer {
    display: block;
    padding-top: 2rem;
    padding-bottom: 2rem;

    &:after {
      content: "";
      width: 3.75rem;
      height: 5px;
      background-color: $wasserblau;
      position: absolute;
      left: calc(50% - 1.875rem);
    }
  }

  .timeline-lead {
    padding-bottom: 2rem;
    font-size: 1.1rem;
  }

  .current-year-button {
    margin: 0px auto;
    color: $weiss !important;
  }

  .more-button {
    margin-top: -14px;
  }

  .timeline-date {
    background-color: $wasserblau;
    padding: 25px 10px;
    color: $weiss;
    margin-top: 0px;
    font-size: 1.2rem;
    text-align: center;

  }

  .timeline {
    list-style: none;
    padding: 55px 0;
    position: relative;
    font-weight: 300;
  }
  .timeline:before {
    top: 0;
    bottom: 0;
    position: absolute;
    content:" ";
    width: 1px;
    background: $timeline-line;
    left: 50%;
    margin-left: -1.5px;
  }
  .timeline > li {
    margin-bottom: 20px;
    position: relative;
    width: 50%;
    float: left;
    clear: left;
  }
  .timeline > li:before, .timeline > li:after {
    content:" ";
    display: table;
  }
  .timeline > li:after {
    clear: both;
  }
  .timeline > li:before, .timeline > li:after {
    content:" ";
    display: table;
  }
  .timeline > li:after {
    clear: both;
  }
  .timeline > li > .timeline-panel {
    width: calc(100% - 25px);
    width: -moz-calc(100% - 25px);
    width: -webkit-calc(100% - 25px);
    float: left;
    border: 1px solid $timeline-border;
    background: #ffffff;
    position: relative;
  }


  /*.timeline > li > .timeline-panel:before {
    position: absolute;
    top: 26px;
    right: -15px;
    display: inline-block;

    border-top: 15px solid transparent;
    border-left: 15px solid $wasserblau;
    border-right: 0 solid $wasserblau;
    border-bottom: 15px solid transparent;
    content:" ";
  }

  .timeline > li.timeline-inverted > .timeline-panel:before {
    left: -0px;
    right: auto;
  }*/


  .timeline > li > .timeline-panel:after {
    position: absolute;
    top: calc(50% - 1px);
    right: -21px;
    width: 20px;
    height: 1px;
    background-color: $timeline-line;
    display: inline-block;
    /*
    border-top: 14px solid transparent;
    border-left: 14px solid $wasserblau;
    border-right: 0 solid $wasserblau;
    border-bottom: 14px solid transparent;
    */
    content:" ";
  }

  .timeline > li.timeline-inverted > .timeline-panel:after {
    left: -21px;
    right: auto;
  }




  .timeline > li > .timeline-badge {
    color: #ffffff;
    width: 24px;
    height: 24px;
    line-height: 50px;
    text-align: center;
    position: absolute;
    top: calc(50% - 24px);
    right: -12px;
    z-index: 100;
  }
  .timeline > li.timeline-inverted > .timeline-panel {
    float: right;
  }





  .timeline-badge > a {
    color: $wasserblau !important;
  }
  .timeline-badge a:hover {
    color: #dcdcdc !important;
  }
  .timeline-title {
    margin-top: 0;
    color: inherit;
  }


  .timeline-heading h4 {
    font-weight: 600;
    margin-top: 15px;
    margin-bottom: 20px;
    font-size: 1.1rem;
    color: $wasserblau;
    text-transform: uppercase;
  }



  .timeline-body > p, .timeline-body > ul {
    margin-bottom: 0;
  }

  .timeline-footer {
    a {
      display: block;
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  .timeline-footer p { margin-bottom: 0; }
  .timeline-footer > a {
    cursor: pointer;
    text-decoration: none;
  }
  .timeline > li.timeline-inverted {
    float: right;
    clear: right;
  }
  .timeline li {
    &:nth-child(even) {
      margin-top: 100px;
    }

    &:nth-child(odd) {
      margin-bottom: 100px;

    }
  }

  .timeline > li.timeline-inverted > .timeline-badge {
    left: -12px;
  }
  .no-float {
    float: none !important;
  }
  @media (max-width: 767px) {

    .timeline li {
      &:nth-child(even) {
        margin-top: 0px;
      }

      &:nth-child(odd) {
        margin-bottom: 0px;
      }
    }

    ul.timeline:before {
      left: 40px;
    }
    ul.timeline > li {
      margin-bottom: 0px;
      position: relative;
      width:100%;
      float: left;
      clear: left;
    }
    ul.timeline > li > .timeline-panel {
      width: calc(100% - 65px);
      width: -moz-calc(100% - 65px);
      width: -webkit-calc(100% - 65px);
    }
    ul.timeline > li > .timeline-badge {
      left: 28px;
      margin-left: 0;
      top: calc(50% - 24px);
    }
    ul.timeline > li > .timeline-panel {
      float: right;
    }
    ul.timeline > li > .timeline-panel:before {
      border-left-width: 0;
      border-right-width: 15px;
      left: -15px;
      right: auto;
    }
    ul.timeline > li > .timeline-panel:after {
      border-left-width: 0;
      border-right-width: 14px;
      left: -21px;
      right: auto;
    }
    .timeline > li.timeline-inverted {
      float: left;
      clear: left;
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .timeline > li.timeline-inverted > .timeline-badge {
      left: 28px;
    }
  }
}
