/** VARIABLES
===================================*/
$controls: '/website/static/images/bxslider/controls.png';
$loader:   '/website/static/images/bxslider/bx_loader.gif';

/** RESET AND LAYOUT
===================================*/

.bx-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0;
  *zoom: 1;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  img {
    max-width: 100%;
    display: block;
  }
}

.bxslider {
  margin: 0;
  padding: 0;
}

ul.bxslider {
  list-style: none;
}

.bx-viewport {
  /*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0);
}

/** THEME
===================================*/

.bx-wrapper {
  .bx-pager,
  .bx-controls-auto {
    position: absolute;
    right: 20px;
    bottom: 30px;
    width: 300px;

  }
}

/* LOADER */
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url($loader) center center no-repeat #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}
/* PAGER */
.bx-wrapper {
  .bx-pager {
    text-align: right;
    font-size: .85em;
    font-family: Arial;
    font-weight: bold;
    color: #666;
    padding-top: 20px;
    &.bx-default-pager a {
      background: transparent;
      border: 1px solid $weiss;
      text-indent: -9999px;
      display: block;
      width: 10px;
      height: 10px;
      margin: 0 5px;
      outline: 0;
      -moz-border-radius: 5px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      &:hover,
      &.active,
      &:focus {
        border-color: $weiss;
        background-color: $weiss;
      }
    }
  }
  .bx-pager-item,
  .bx-controls-auto .bx-controls-auto-item {
    display: inline-block;
    *zoom: 1;
    *display: inline;
  }
  .bx-pager-item {
    font-size: 0;
    line-height: 0;
  }
}
/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper {
  .bx-prev {
    left: 0px;
    background: rgba(255,255,255, .4);
    width: 40px;
    height: 100px;
    font-size:40px;
    padding-top: 5px;
    padding-left: 6px;
    text-align: left;

    //background: url($controls) no-repeat 0 -32px;
    &:hover,
    &:focus {
      background-color: $wasserblau;
    }
  }
  .bx-next {
    right: 0px;
    background: rgba(255,255,255, .4);
    width: 40px;
    height: 100px;
    font-size:40px;
    padding-top: 5px;
    padding-right: 6px;
    text-align: right;

    &:hover,
    &:focus {
      background-color: $wasserblau;
    }
  }
  .bx-controls-direction a {
    position: absolute;
    top: 50%;
    margin-top: -35px;
    outline: 0;
    width: 30px;
    height: 70px;
    color: rgba(255,255,255, 0.7);
    //text-indent: -9999px;
    z-index: 9999;
    &.disabled {
      display: none;
    }
  }
}

/* AUTO CONTROLS (START / STOP) */
.bx-wrapper .bx-controls-auto {
  text-align: center;
  .bx-start {
    display: block;
    text-indent: -9999px;
    width: 10px;
    height: 11px;
    outline: 0;
    background: url($controls) -86px -11px no-repeat;
    margin: 0 3px;
    &:hover,
    &.active,
    &:focus {
      background-position: -86px 0;
    }
  }
  .bx-stop {
    display: block;
    text-indent: -9999px;
    width: 9px;
    height: 11px;
    outline: 0;
    background: url($controls) -86px -44px no-repeat;
    margin: 0 3px;
    &:hover,
    &.active,
    &:focus {
      background-position: -86px -33px;
    }
  }
}
/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
.bx-wrapper {
  .bx-controls.bx-has-controls-auto.bx-has-pager {
    .bx-pager {
      text-align: left;
      width: 80%;
    }
    .bx-controls-auto {
      right: 0;
      width: 35px;
    }
  }
}
/* IMAGE CAPTIONS */
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666;
  background: rgba(80, 80, 80, 0.75);
  width: 100%;
  span {
    color: #fff;
    font-family: Arial;
    display: block;
    font-size: .85em;
    padding: 10px;
  }
}