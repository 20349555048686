.color--line {
  background: url('/website/static/images/layout/balken-head.png') no-repeat;
  background-size: 100%;
  width: auto;
  height: 24px;
}

.icon--bar {
  //background-color: $grau;
  height: 1.813rem;
  color: $text-color;
  max-width: 1420px;
  margin: 0px auto;
  //margin-top: .5rem;

  @media(max-width: 554px) {
    margin-top: -1rem;
    margin: 0px auto;
    text-align: center;

  }

  ul {

    margin-right: 1rem;
    font-size: 0.75rem;

    li {
      padding-right: 1rem;
    }


    .language--selector {
      //margin-right: 2.5rem;
      //margin-top: 0.5rem;
      //float: right;

      .nav-link {
        color: $text-color;
        padding: 0;
      }

      &:hover {
        .nav-link {
          color: $wasserblau;
        }
        /*
        .dropdown-menu {
          display: block;
        }
        */
      }

      span {
        margin-top: 0.2rem
      }

      .dropdown-menu {
        z-index: 9999999;
        left: -1.63rem;
        min-width: 100px;
        margin-top: 0.8rem;
        -webkit-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.41);
        -moz-box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.41);
        box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.41);
        border: none;
        border-radius: 0px;
        background-color: #FFF;

        &:after {
          content: '';
          position: absolute;
          border-style: solid;
          border-width: 0 9px 9px;
          border-color: $wasserblau transparent;
          display: block;
          width: 0;
          z-index: 1;
          top: -9px;
          left: 26px;
        }
      }
    }

    .phone {
      a {
        color: $text-color;
        &:hover {
          color: $wasserblau;
          text-decoration: none;
        }
      }
      //margin-top: 0.6rem;
      float: right;

      i {
        color: $wasserblau;
        margin-right: 0.4rem;
      }
    }
  }

}