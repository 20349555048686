
/*.textblock {
  @media(min-width: 768px) {
    padding-left: 5em;

    h1:first-of-type, h3.newsletter-heading {
      margin-left: -2em;

      &:after {
        content: "";
        width: 3.75rem;
        height: 5px;
        background-color: $wasserblau;
        position: absolute;
        left: 1.2rem;
        margin-top: 3.59rem;
      }
    }

    i.icon-top{
      margin-left: -1.2em;
      margin-bottom: 1rem;
    }

    h5:first-child {
      margin-top: 0.7rem
    }
  }
}
*/

.content {

  background-color: $weiss;

  @media(max-width: 991px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  @media(min-width: 992px) {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }


  @media screen and (min-width: 768px) {
    //background-image: url(/website/static/images/layout/bg-two-color.png);
  }
  background-size: 100%;
  background-repeat: repeat-y;

  .two--colors {
    .left {
      min-height: 630px;
      .btn-primary {
        color: $weiss;
      }
      padding-top: 5rem;
      padding-bottom: 5rem;
      background-color: #fff;

      @media screen and (min-width: 768px) {
        padding-right: 8em;
      }

    }
    .right {
      color: $weiss;
      background-color: $wasserblau;

      position: sticky;
      top: 0px;

      @media screen and (max-width: 767px) {
        background-color: $weiss;
      }

      // mit sidebar - icons rechts
      .sidebar--icons {
        li {
          a {
            display: block;
            width: 100%;
            height: 100%;
          }
          a:hover {
            .icon {
              background-color: $weiss;
              border-color: $weiss;
              i {
                color: $wasserblau;
              }
              .title {
                color: $wasserblau;
              }
              .next-button {
                i {
                  color: $weiss;
                }
                background-color: $wasserblau;
              }
            }
          }

          .icon {
            text-align: center;
            width: 6rem;
            height: 7rem;
            border: 1px solid $weiss;
            float: right;
            margin-top: 1rem;
            margin-bottom: 1rem;

            i {
              color: $weiss;
              padding: 10px;
              padding-top: 15px;
              padding-bottom: 5px;
              font-size: 2rem;
              margin: 0px auto;
            }
            .title {
              color: $weiss;
              padding-top: 2px;
              padding-left: 4px;
              padding-right: 4px;
              font-size: .8rem;
              text-transform: uppercase;
            }
            .next-button {
              position: relative;
              left: 0px;
              width: 15px;
              height: 15px;
              margin-top: -1px;
              margin-left: -0px;
              color: $wasserblau;
              font-size: 0.5rem;
              background-color: $weiss;

              i {
                color: $wasserblau;
                margin: 0;
                padding: 0;
                font-size: 1rem;
              }
            }
          }
        }
      }

      //ohne sidebar - icons unten
      @media screen and (max-width: 767px) {
        .sidebar--icons {
          display: table;
          vertical-align: middle;
          width: 100%;
          text-align: center;

          li {
            display: table-cell;
            text-align: center;
            vertical-align: middle;

            float: left;
            margin-right: 1rem;
            margin-bottom: 1rem;
            a {
              display: block;
              .icon {
                background-color: $wasserblau;
                border-color: $wasserblau;
                i {
                  color: $weiss;
                }
                .title {
                  color: $weiss;
                }
                .next-button {
                  i {
                    color: $wasserblau;
                  }
                  background-color: $weiss;
                }
              }
              &:hover {
                .icon {
                  border-color: $wasserblau;
                }
              }
            }
          }
        }

      }
    }
  }
}
