$fa-font-path: "/website/static/build/fonts/font-awesome" !default;
@import "font-awesome";

@import "octicons.scss";
// example usage of font-face mixin
// @include font-face('Raleway', 'fonts/raleway/raleway-regular');


@font-face {
  font-family: 'Corbel';
  src: url('/website/static/build/fonts/private/Corbel.eot?#iefix') format('embedded-opentype'),  url('/website/static/build/fonts/private/Corbel.woff') format('woff'), url('/website/static/build/fonts/private/Corbel.ttf')  format('truetype'), url('/website/static/build/fonts/private/Corbel.svg#Corbel') format('svg');
  font-weight: normal;
  font-style: normal;
}


@font-face {
  font-family: 'Corbel';
  src: url('/website/static/build/fonts/private/Corbel-BoldItalic.eot?#iefix') format('embedded-opentype'),  url('/website/static/build/fonts/private/Corbel-BoldItalic.woff') format('woff'), url('/website/static/build/fonts/private/Corbel-BoldItalic.ttf')  format('truetype'), url('/website/static/build/fonts/private/Corbel-BoldItalic.svg#Corbel-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}


@font-face {
  font-family: 'Corbel';
  src: url('/website/static/build/fonts/private/Corbel-Italic.eot?#iefix') format('embedded-opentype'),  url('/website/static/build/fonts/private/Corbel-Italic.woff') format('woff'), url('/website/static/build/fonts/private/Corbel-Italic.ttf')  format('truetype'), url('/website/static/build/fonts/private/Corbel-Italic.svg#Corbel-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Corbel';
  src: url('/website/static/build/fonts/private/Corbel-Bold.eot?#iefix') format('embedded-opentype'),  url('/website/static/build/fonts/private/Corbel-Bold.woff') format('woff'), url('/website/static/build/fonts/private/Corbel-Bold.ttf')  format('truetype'), url('/website/static/build/fonts/private/Corbel-Bold.svg#Corbel-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}
