.followup--image {
  background-color: $hellgrau;
  max-width: 1420px;
  margin: 0px auto;

  .container {
    .left {

      padding: 0px;
      .image--container {

        img {
          width: 100%;
        }

        &:after {
          content:'';
          color:#fff;
          position:absolute;
          width:100%;
          height:100%;
          top:0;
          left:0;
          background:rgba(0,0,0,0.7);
          opacity:.6;
          transition: all 0.5s;
          -webkit-transition: all 0.5s;
        }

        /*&:hover:after {
          opacity:1;
        }*/

      }



      .overlay {
        position: absolute;
        right: 4rem;
        bottom: 3rem;
        text-align: center;
        .title {
          font-weight: bold;
          color: $wasserblau;
          text-transform: uppercase;
          padding-bottom: 1rem;
          font-size:3.5rem;
          @media(max-width: 768px) {
            font-size: 2rem;
          }

        }
        .btn-outline-primary {
          font-size: 1.1rem;
          color: $weiss;
          border-color: $weiss;
          text-transform: uppercase;

          &:hover {
            background-color: $wasserblau;
            border-color: $wasserblau;
            color: $weiss;
            text-decoration: none;
          }
        }
      }


    }
    .right {

    }
  }
}